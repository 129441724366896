import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {PixelService} from "ngx-multi-pixel";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'guard-training-frontend';

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private pixelService: PixelService
  ) {
  }

  ngOnInit(): void {
    this.gtmService.addGtmToDom();
    this.pixelService.initialize();
  }
}
